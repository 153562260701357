body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.simplebar-placeholder {
  display: none;
}

.PhoneInput.PhoneInput--disabled input#phone_number {
  color: #929fac;
}

.PhoneInput.PhoneInput--disabled input#phone_number:hover{
  border-color: #e5e8eb;
}

/* Scrollbar width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8C51F5;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(140, 81, 245, 0.75);
}

.custom_form {
  max-height: calc(100vh - 292.8px);
  max-width: 450px;
  overflow: auto;
  padding: 5px;
}

.MuiMenu-list{
  max-height: 50vh;
}

.upload-container {
  position: relative;
  display: inline-block;
}

.upload-area {
  text-align: center;
}

.placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.not-btn{
  cursor: default;

}
.not-btn .MuiTouchRipple-root{
  display: none !important;
}
.not-btn:hover ,.not-btn:active,.not-btn:focus-visible{
  background-color: unset;
  outline: unset;
  border: none;
}
.not-btn .MuiTouchRipple-root:hover ,.not-btn .MuiTouchRipple-root:active,.not-btn .MuiTouchRipple-root:focus-visible{
background-color: unset;
outline: unset;
border: none;
           }


.loader {
  position: absolute;
  top: 0;
  width: 144px;
  height: 144px;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #8c51f5;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  transform: translate(-50%, -50%);
}

.inline-btn{
  cursor: pointer;
}

.inline-btn:hover{
  color: #8c51f5;
}

.row-style{
  padding: 15px;
  border: solid white 5px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

